/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d2ad5203-baef-4e58-bb4e-34774ac8e55f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tOHnN8Elr",
    "aws_user_pools_web_client_id": "3rrec3q2br169of4pcgi3nu3ue",
    "oauth": {
        "domain": "cartoseq9b7929d8-9b7929d8-test.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://test.seqscope.org/",
        "redirectSignOut": "https://test.seqscope.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "artoeq-20220522190214-hostingbucket-test",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1s3zjzne8iog8.cloudfront.net"
};


export default awsmobile;
